/**
 * UI корзины
 */

/**
 * Обновлялка блока с корзиной
 */
var updateCartBlock = function () {
    var $cart = $(".js-shopping_cart");
    if ($cart.length) {

        var totalSum = 0,
                totalCount = 0;

        if (Cart.items.length > 0) {
            //смена вида корзины если она была «пустая»
            $cart.show();

            for (var i = 0; i < Cart.items.length; i++) {
                totalSum += Cart.items[i].price * Cart.items[i].qty;
                totalCount += Cart.items[i].qty;
            }

            $cart.find(".js-shopping-cart__total_count_txt").text(totalCount);
            $cart.find(".js-shopping_cart__total_price").html(Meta.beautyPrice(totalSum) + ' <i class="rouble">о</i>');
        } else {
            $cart.hide();
        }
        // footerbasket();
    }
};


/**
 * Анимация страницы корзины
 */
var emptyCartPageAnimation = function () {
    $("#js-shopping_cart_page__empty").show();
    $("#js-shopping_cart_page__filled").hide();
}


/**
 *обновлялка инфы на странице корзины
 */
var updateCartPage = function () {
    var totalQtyText = "",
            totalQty = 0,
            totalPrice = 0,
            $pageFilled = $("#js-shopping_cart_page__filled");

    for (var item_id in Cart.items) {
        totalPrice += Cart.items[item_id].price * Cart.items[item_id].qty;
        totalQty += Cart.items[item_id].qty;
    }

    totalQtyText = Meta.decline(totalQty, "", "%n товар", "%n товара", "%n товаров");

    if (totalQty) {
        $pageFilled.find(".js-shopping-cart__total_count_txt").text(totalQtyText);
        $pageFilled.find(".js-shopping_cart__total_price").html(Meta.beautyPrice(totalPrice) + ' <i class="rouble">о</i>');
    } else {
        emptyCartPageAnimation();
    }
};

/**
 * Обновлялка кнопок +/- 
 */
var updateCountButtons = function (newQty, $itemBlock) {
    if (newQty == Cart.maxItemQty) {
        $itemBlock
                .find(".js-catalog_item_quantity__increase")
                .addClass("disable_button_class");
    } else {
        $itemBlock
                .find(".js-catalog_item_quantity__increase")
                .removeClass("disable_button_class");
    }

    if (newQty == 1) {
        $itemBlock
                .find(".js-catalog_item_quantity__decrease")
                .addClass("disable_button_class");
    } else {
        $itemBlock
                .find(".js-catalog_item_quantity__decrease")
                .removeClass("disable_button_class");
    }
}


$(function () {

    // Кнопка "забронировать" в рекомендуемом коплекте 
    $("#js-recommended_set").on("click", "#js-cart_recommendedset", function (e) {
        var $itemBlock = $(e.delegateTarget),
                items = [];

        $itemBlock.find("input:checked").each(function () {
            var
                    id = $(this).attr("data-id"),
                    price = $(this).attr("data-price"),
                    qty = 1;

            items.push({
                id: id,
                qty: qty,
                price: price
            });
        });

        Cart.putItems(items, function () {
            updateCartBlock();
        });

        e.preventDefault();
    });


    //кнопка купить
    $(".js-catalog_item").on("click", ".js-catalog_item_add_to_cart", function (e) {
        var $itemBlock = $(e.delegateTarget),
                id = parseInt($itemBlock.attr('data-item_id'), 10),
                price = parseFloat($itemBlock.attr('data-item_price').replace(',', '.')),
                qty = 1;

        Cart.putItem({
            id: id,
            qty: qty,
            price: price,
        }, false, function () {
            updateCartBlock();
        });

        e.preventDefault();
    });

    //инпут с количеством
    //на странице корзины — покупает
    //на странице товара — ждет
    $("#js-catalog_items").on("change", ".js-catalog_item_quantity", function (e) {
        var $itemBlock = $(this).parents(".js-catalog_item"),
                id = parseInt($itemBlock.attr('data-item_id'), 10),
                price = parseFloat($itemBlock.attr('data-item_price').replace(',', '.')),
                newQty = parseInt($(this).val(), 10),
                totalitemprice = $itemBlock.find(".js-catalog_item_total");

        if (!newQty || (newQty < 0)) {
            newQty = 1;
        }

        if (newQty > Cart.maxItemQty) {
            newQty = Cart.maxItemQty;
        }

        updateCountButtons(newQty, $itemBlock);

        if ($itemBlock.attr("data-cart_mode") == "set") {

            totalitemprice.html(Meta.beautyPrice(price * newQty) + ' <i class="rouble">о</i>');

            Cart.putItem({
                id: id,
                qty: newQty,
                price: price,
            }, true, function () {
                updateCartBlock();
            });
        }

        $(this).val(newQty);

        updateCartBlock();
        updateCartPage();

        e.preventDefault();
    });

    //кнопка плюс
    //на странице корзины — покупает
    //на странице товара — ждет
    $("#js-catalog_items").on("click", ".js-catalog_item_quantity__increase", function (e) {
        var $itemBlock = $(this).parents(".js-catalog_item"),
                id = parseInt($itemBlock.attr('data-item_id'), 10),
                price = parseFloat($itemBlock.attr('data-item_price').replace(',', '.')),
                inputVal = parseInt($itemBlock.find(".js-catalog_item_quantity").val()),
                newQty,
                totalitemprice = $itemBlock.find(".js-catalog_item_total");

        if (!inputVal) {
            newQty = 1;
        } else {
            newQty = (inputVal + 1).toFixed(1) * 1;
        }

        if (newQty > Cart.maxItemQty) {
            newQty = Cart.maxItemQty;
        }

        updateCountButtons(newQty, $itemBlock);

        if ($itemBlock.attr("data-cart_mode") === "set") {
            totalitemprice.html(Meta.beautyPrice(price * newQty) + ' <i class="rouble">о</i>');

            Cart.putItem({
                id: id,
                qty: newQty,
                price: price
            }, true, function () {
                updateCartBlock();
            });
        }

        $itemBlock.find(".js-catalog_item_quantity").val(newQty);

        updateCartBlock();
        updateCartPage();

        e.preventDefault();
    });

    //кнопка минус
    //на странице корзины — покупает
    //на странице товара — ждет
    $("#js-catalog_items").on("click", ".js-catalog_item_quantity__decrease", function (e) {
        var $itemBlock = $(this).parents(".js-catalog_item"),
                id = parseInt($itemBlock.attr('data-item_id'), 10),
                price = parseFloat($itemBlock.attr('data-item_price').replace(',', '.')),
                inputVal = parseInt($itemBlock.find(".js-catalog_item_quantity").val()),
                newQty,
                totalitemprice = $itemBlock.find(".js-catalog_item_total");

        if (!inputVal || inputVal === 1) {
            newQty = 1;
        } else {
            newQty = (inputVal - 1).toFixed(1) * 1;
        }

        updateCountButtons(newQty, $itemBlock);

        if ($itemBlock.attr("data-cart_mode") === "set") {

            totalitemprice.html(Meta.beautyPrice(price * newQty) + ' <i class="rouble">о</i>');
            Cart.putItem({
                id: id,
                qty: newQty,
                price: price
            }, true, function () {
                updateCartBlock();
            });
        }

        $itemBlock.find(".js-catalog_item_quantity").val(newQty);

        updateCartBlock();
        updateCartPage();

        e.preventDefault();
    });

    //сртаница корзины
    //удаление товара
    $("#js-catalog_items").on("click", ".js-catalog_item_remove", function (e) {
        var $htmlObj = $(this).parents(".js-catalog_item"),
                id = parseInt($htmlObj.attr("data-item_id"), 10);

        if (Cart.items.length > 1) {
            $htmlObj.slideUp(70);
        } else {
            emptyCartPageAnimation();
        }

        Cart.removeItem({id: id}, function () {
            updateCartBlock();
            updateCartPage();
        });

        e.preventDefault();
    });

    $("#js-shopping_cart_clear").on("click", function (e) {
        Cart.removeAll(function () {
            emptyCartPageAnimation();
            updateCartBlock();
        });

        e.preventDefault();
    });


    Cart.onInitCallback = function () {
        updateCartBlock();
        updateCartPage();
    };
});


