$(function () {

    /** Главный слайдер **/
    var swiper = new Swiper('.swiper-container_1', {
        pagination: '.swiper-pagination',
        nextButton: '.swiper-button-next',
        prevButton: '.swiper-button-prev',
        paginationClickable: true,
        spaceBetween: 0,
        slidesPerView: 1,
        loop: true,
        autoplay: 5000,
        roundLengths: true,
        autoplayDisableOnInteraction: false,
        grabCursor: true
    });


    /** Слайдеры карточек товаров **/
    var swiper = new Swiper('.swiper-container_2', {
        nextButton: '.swiper-button-next',
        prevButton: '.swiper-button-prev',
        paginationClickable: true,
        loop: true,
        slidesPerView: 3,
        spaceBetween: 26,
        roundLengths: false,
        breakpoints: {
            600: {
                slidesPerView: 1,
                spaceBetweenSlides: 10
            },
            1199: {
                slidesPerView: 2,
                spaceBetweenSlides: 26
            }
        },
    });


    /** Слайдеры преимуществ **/
    var swiper = new Swiper('.swiper-container_3', {
        nextButton: '.swiper-button-next',
        prevButton: '.swiper-button-prev',
        paginationClickable: true,
        loop: true,
        autoplay: 3000,
        autoplayDisableOnInteraction: false,
        grabCursor: true,
        slidesPerView: 3,
        spaceBetween: 26,
        roundLengths: true,
        breakpoints: {
            600: {
                slidesPerView: 1,
                spaceBetweenSlides: 10
            },
            1199: {
                slidesPerView: 2,
                spaceBetweenSlides: 26
            }
        }
    });


    /** Вызов модальных окон **/
    $('.default_popup').magnificPopup({
        type: 'inline',
        preloader: false,
        focus: '#name',
        // When elemened is focused, some mobile browsers in some cases zoom in
        // It looks not nice, so we disable it:
        callbacks: {
            beforeOpen: function () {
                if ($(window).width() < 767) {
                    this.st.focus = false;
                } else {
                    this.st.focus = '#name';
                }
            }
        }
    });

    /** Модалка "Под заказ" */
    $(".orderproduct_popup").magnificPopup({
        type: 'inline',
        preloader: false,
        focus: '#name',
        id: "",
        title: "",
        uri: "",
        // When elemened is focused, some mobile browsers in some cases zoom in
        // It looks not nice, so we disable it:
        callbacks: {
            beforeOpen: function () {
                if ($(window).width() < 767) {
                    this.st.focus = false;
                } else {
                    this.st.focus = '#name';
                }
            },
            open: function () {
                var
                        $context = $(this.currItem.el.context),
                        $content = $(this.content[0]),
                        $a = $content.find("a.product");
                $input = $content.find("input[name=product_id]");

                $input.val($context.attr("data-item_id"));
                $a.attr("href", $context.attr("data-item_uri")).text($context.attr("data-item_title"));
            },
            beforeClose: function () {
                $("#js-form__reload-orderproduct").trigger('click');
            }
        }
    });

    $(".low_price_popup").magnificPopup({
        type: 'inline',
        preloader: false,
        focus: '#name',
        id: "",
        title: "",
        uri: "",
        // When elemened is focused, some mobile browsers in some cases zoom in
        // It looks not nice, so we disable it:
        callbacks: {
            beforeOpen: function () {
                if ($(window).width() < 767) {
                    this.st.focus = false;
                } else {
                    this.st.focus = '#name';
                }
            },
            open: function () {
                var
                        $context = $(this.currItem.el.context),
                        $content = $(this.content[0]),
                        $a = $content.find("a.product");
                $input = $content.find("input[name=product_id]");

                $input.val($context.attr("data-item_id"));

                $a.attr("href", $context.attr("data-item_uri")).text($context.attr("data-item_title"));
            },
            beforeClose: function () {
                $("#js-form__reload-low_price").trigger('click');
            }
        }
    });

    $(".receipt_popup").magnificPopup({
        type: 'inline',
        preloader: false,
        focus: '#name',
        id: "",
        title: "",
        uri: "",
        // When elemened is focused, some mobile browsers in some cases zoom in
        // It looks not nice, so we disable it:
        callbacks: {
            beforeOpen: function () {
                if ($(window).width() < 767) {
                    this.st.focus = false;
                } else {
                    this.st.focus = '#name';
                }
            },
            open: function () {
                var
                        $context = $(this.currItem.el.context),
                        $content = $(this.content[0]),
                        $a = $content.find("a.product");
                $input = $content.find("input[name=product_id]");

                $input.val($context.attr("data-item_id"));

                $a.attr("href", $context.attr("data-item_uri")).text($context.attr("data-item_title"));
            },
            beforeClose: function () {
                $("#js-form__reload-receipt").trigger('click');
            }
        }
    });

    // Рекомендуемый комплект
    if ($("#js-recommended_set").length) {

        var recalc = function () {
            var
                    $block = $("#js-recommended_set"),
                    $totalSumma = $block.find("#js-recommended_set-totalprice"),
                    summa_complect = 0;

            $block.find("input:checked").each(function () {
                summa_complect += parseInt($(this).attr("data-price"));
            });
            $totalSumma.html(Meta.beautyPrice(summa_complect) + ' <i class="rouble">о</i>');
        }

        /** Обработка события "В комплект" **/
        $("#js-recommended_set").on("change", ".js-check_item_group", recalc);

        /** Вызов модальных окон **/
        $('.recommend_items_popup').magnificPopup({
            type: 'inline',
            preloader: false,
            focus: '#name',
            // When elemened is focused, some mobile browsers in some cases zoom in
            // It looks not nice, so we disable it:
            callbacks: {
                beforeOpen: function () {
                    if ($(window).width() < 767) {
                        this.st.focus = false;
                    } else {
                        this.st.focus = '#name';
                    }
                },
                open: function () {
                    var
                            $context = $(this.currItem.el.context),
                            $recommendedgroupitem = $context.prev(".js-recommended_group-item"),
                            $content = $(this.content[0]),
                            group = $context.attr("data-group"),
                            $block_insert = $content.find("#items-insert");

                    $block_insert.html("");
                    $context.next("#js-group" + group).children("div").each(function (e) {
                        var $div = $("<div>").addClass("col-lg-4 col-sm-4").append($(this).clone());
                        $block_insert.append($div);
                    });

                    $(".js-catalog_item").on("click", ".js-groups-select", function (e) {
                        var
                                group = $(this).attr("data-group"),
                                ajaxLink = $(this).attr("href");

                        $.get(ajaxLink, {'group': group}, function (answ) {
                            $recommendedgroupitem.html(answ);
                            recalc();
                            $.magnificPopup.close();
                        });
                        e.preventDefault();
                    });
                }
            }
        });
    }

    var $tooltip;

    /** Тултипы **/
    $('.tooltip, .tooltip_2').on('click', function (e) {
        $tooltip = $(this).siblings('.tooltip_content')
        e.preventDefault();
        $tooltip.toggleClass('active');
    });

    $(".js-tooltip_close").on('click', function () {
        $(this).parents(".tooltip_content").toggleClass('active');
        return false;
    });

    $(".js-catalog_item_add_to_cart").on("click", function (e) {
        if ($(this).text() != "Оформить заказ") {
            $(this).text("Оформить заказ");
        } else {
            $(this).removeClass("js-catalog_item_add_to_cart");
            location.href = $(this).attr("href");
        }
    });


    /** Коллбэк на ресайз окна **/
    var onWindowResizeStop = function (callback) {
        var rtime = new Date();
        var timeout = false;
        var delta = 200;
        $(window).resize(function () {
            rtime = new Date();
            if (timeout === false) {
                timeout = true;
                setTimeout(windowResizeEnd, delta);
            }
        });

        function windowResizeEnd() {
            if (new Date() - rtime < delta) {
                setTimeout(windowResizeEnd, delta);
            } else {
                timeout = false;

                callback();
            }
        }
    };


    /** Подсчет высоты колонок **/
    var makeCustomHeight = function () {
        var maxHeight = 0;
        $(".js_single-height-block").height("");

        $(".js_single-height-block").each(function () {
            if (maxHeight < $(this).outerHeight()) {
                maxHeight = $(this).outerHeight();
            }
        });

        $(".js_single-height-block").height(maxHeight);
    };


    /** Аккордион **/
    $(".js_category_nav").hide();

    $(".js_toggle_submenu").on("click", function () {
        if ($(this).hasClass("accordion_open")) {
            $(this).removeClass("accordion_open");
            $(this).addClass("accordion_close");

            $(".js_category_nav").slideUp(300, function () {
                makeCustomHeight();
            });
        } else {
            $(this).removeClass("accordion_close");
            $(this).addClass("accordion_open");

            $(".js_category_nav").slideDown(300, function () {
                makeCustomHeight();
            });
        }
        return false;
    });

    /* $(document).ready(function(){
     makeCustomHeight();
     });
     onWindowResizeStop(makeCustomHeight); */

    if ($("#galleria").length) {
        /** Подгружаем тему для галереи **/
        Galleria.loadTheme('/static/js/lib/3rdparty/galleria/themes/classic/galleria.classic.js');

        /** Инициализируем галерею **/
        Galleria.run('#galleria', {
            height: 1,
            showInfo: false,
            showCounter: false
        });
    }

    if ($(".button_top")) {
        $(window).scroll(function () {
            if ($(this).scrollTop() > 300) {
                $('.button_top').fadeIn();
            } else {
                $('.button_top').fadeOut();
            }
        });

        $(".button_top").on("click", function () {
            $("html, body").animate({
                scrollTop: 0
            }, 600);
            return false;
        });
    }

    if ($("#js-more_description-button").length) {
        $("#js-more_description-button").on("click", function () {
            $("#js-full_description").show();
            $("#js-more_description").hide();
            $(this).remove();
            return false;
        });
    }

    if ($(".js-favorite_button").length) {
        $(".js-favorite_button").on("click", function () {
            var
                    id = $(this).parents(".catalog_item").attr("data-item_id"),
                    $this = $(this);

            $.get("/favorite/", {'id': id}).done(function (answ) {
                answ = JSON.parse(answ);
                if (answ.ok) {
                    if ((!answ.is) && (/favorite/.test(location.href))) {
                        $this.parents(".catalog_item").parent("div").remove();
                    }
                    $(".js-favorite_count").text(answ.value);
                    $this.toggleClass('item_star').toggleClass('item_star_selected');
                } else {
                    alert(answ.error);
                }
                footerbasket();
            });
            return false;
        });
    }

    if ($(".js-comparison_button").length) {
        $(".js-comparison_button").on("click", function () {
            var
                    id = $(this).parents(".catalog_item").attr("data-item_id"),
                    $this = $(this);

            $.get("/comparison/", {'id': id}).done(function (answ) {
                answ = JSON.parse(answ);
                if (answ.ok) {
                    $(".js-comparison_count").text(answ.value);
                    $this.toggleClass('item_comparison').toggleClass('item_comparison_selected');
                } else {
                    alert(answ.error);
                }
                footerbasket();
            });
            return false;
        });
    }

    if ($(".js-viewed_count").length) {
        $.get("/shop/viewed/").done(function (answ) {
            $(".js-viewed_count").text(answ);
            footerbasket();
        });
    }

    if ($(".js-filter_price").length) {

        var price = {'min': null, 'max': null}

        $(".js-filter_price").on("focus", function () {
            $(".js-filter_price").next().addClass("button_price_filter_hidden");
            $(this).next().removeClass("button_price_filter_hidden");
        });

        $(".js-filter_price").on("change", function () {
            if ($(this).attr('id') == 'min_price') {
                price.min = $(this).val();
            }
            if ($(this).attr('id') == 'max_price') {
                price.max = $(this).val();
            }
        });

        var $form = $(".js-catalog_filter form");

        $(".js-search_button").on("click", function () {
            $form.submit();
        });

        $(".js-tag_selected").on("click", function () {
            $form.find("input[name=tag]").val($(this).attr('data-tag_name'));
            $form.submit();
            return false;
        });

        $("input[name=stock]").on("change", function () {
            $form.submit();
        });

        $("select[name=sort]").on("change", function () {
            $form.submit();
        });
    }


    if ($(".js-comparison_link").length) {
        $(".js-comparison_link").on("click", function () {
            var
                    id = $(".item_article_row").attr('data-id'),
                    $this = $(this);
            $.get("/comparison/", {'id': id}).done(function (answ) {
                answ = JSON.parse(answ);
                if (answ.ok) {
                    if (answ.is == 1) {
                        $(".js-comparison_count").text(answ.value);
                        $this.addClass('comparison_link_active');
                        $(this).text("Убрать из сравнения");
                    } else {
                        $(".js-comparison_count").text(answ.value);
                        $this.removeClass('comparison_link_active');
                        $this.text("Сравнить");
                    }
                } else {
                    alert(answ.error);
                }
                footerbasket();
            });
            return false;
        });
    }

    if ($(".js-favourite_link").length) {
        $(".js-favourite_link").on("click", function () {
            var
                    id = $(".item_article_row").attr('data-id'),
                    $this = $(this);
            $.get("/favorite/", {'id': id}).done(function (answ) {
                answ = JSON.parse(answ);
                if (answ.ok) {
                    if (answ.is == 1) {
                        $(".js-favorite_count").text(answ.value);
                        $this.addClass('favourite_link_active');
                        $this.text("Удалить из избранного");
                    } else {
                        $(".js-favorite_count").text(answ.value);
                        $this.removeClass('favourite_link_active');
                        $this.text("В избранное");
                    }
                } else {
                    alert(answ.error);
                }
                footerbasket();
            });
            return false;
        });
    }

    if ($(".js-comparison_delete").length) {
        $(".js-comparison_delete").on("click", function () {
            var id = $(this).parents("td").attr("data-product_id");
            $.get("/comparison/", {'id': id}).done(function (answ) {
                answ = JSON.parse(answ);
                if (answ.ok) {
                    var
                            $table = $("#js-table_comparison"),
                            category_id = $table.attr("data-category_id"),
                            $countcategory = $("#js-comparison_count-" + category_id);

                    $table.find("td[data-product_id=" + id + "]").remove();

                    $countcategory.text(parseInt($countcategory.text()) - 1);

                    if (!$table.find("td").length) {
                        location.href = $(".js-comparison_delete-category").attr("href");
                    }
                } else {
                    alert(answ.error);
                }
                footerbasket();
            });
            return false;
        });
    }

    var initFileUploader = function () {
        var $fileUploader = $('#file_uploader');

        if ($fileUploader.length) {
            var updateFilesIdentify = function () {
                var input = $fileUploader.find("#files"),
                        newValues = [];

                input.val("");

                $fileUploader
                        .find(".js-file_uploader__file")
                        .each(function () {
                            newValues.push({
                                identify: $(this).attr("data-identify"),
                                path: $(this).attr("data-file_path"),
                            });
                        });

                input.val(JSON.stringify(newValues));
            };

            var $fileInfoTemplate = $fileUploader.find("#js-file_uploader__file");

            $fileUploader
                    .find("#file_uploader__input")
                    .fileupload({
                        dataType: 'json',
                        maxChunkSize: 5000000, // 5 MB
                        url: "/file-upload/",
                        paramName: "files[]",
                        add: function (e, data) {
                            var identify = null,
                                    $item = null;

                            $.ajax({
                                async: false,
                                type: 'POST',
                                dataType: 'json',
                                url: '/file-upload/get-identify/',
                                success: function (response) {
                                    if (response.success) {
                                        identify = response.identify;

                                        $item = $fileInfoTemplate.clone();
                                        $item
                                                .removeAttr("id")
                                                .addClass("js-file_uploader__file")
                                                .insertAfter($fileInfoTemplate)
                                                .attr("data-identify", identify)
                                                .show();

                                        $item.find(".js-file_uploader__file_name").text(data.files[0].name);

                                        data.files[0].identify = identify;
                                    } else {
                                        alert(response.message);
                                    }
                                },
                            });


                            if (!identify) {
                                data.abort();
                                return false;
                            }


                            data.formData = {identify: identify};

                            if (e.isDefaultPrevented()) {
                                return false;
                            }
                            if (data.autoUpload || (data.autoUpload !== false && $(this).fileupload('option', 'autoUpload'))) {
                                data.process().done(function () {
                                    var jqXHR = data.submit();

                                    $item.find(".js-file_uploader__file_remove").on("click", function () {
                                        $item.remove();
                                        jqXHR.abort();
                                        updateFilesIdentify();
                                        $fileUploader.find("#file_uploader__input").removeAttr("disabled");
                                        return false;
                                    });
                                });
                            }
                        }
                    })
                    .bind('fileuploadprogress', function (e, data) {
                        var progress = parseInt(data.loaded / data.total * 100, 10);

                        $fileUploader
                                .find(".js-file_uploader__file[data-identify=" + data.files[0].identify + "]")
                                .find(".js-file_uploader__file_percent")
                                .text("(загружено - " + progress + "%)");
                    })
                    .bind('fileuploadfail', function (e, data) {
                        console.log("fail");
                    })
                    .bind('fileuploadchunkfail', function (e, data) {
                        console.log("chunk fail");
                    })
                    .bind('fileuploaddone', function (e, data) {
                        var id = data.files[0].identify,
                                $item = $fileUploader
                                .find(".js-file_uploader__file[data-identify=" + id + "]");

                        if (data.result.files[0].hasOwnProperty("error")) {
                            alert("Ошибка при загрузке файла " + data.result.files[0].name);
                            $item.remove();

                        } else {
                            $item
                                    .attr("data-file_path", data.result.files[0].url);

                            $item
                                    .find(".js-file_uploader__file_remove")
                                    .text("удалить");

                            $item.find(".js-file_uploader__file_percent").hide();
                            $fileUploader.find("#file_uploader__input").attr("disabled", "disabled");

                            updateFilesIdentify();
                        }
                    });
        }
    };

    MetaFormsHandler.bind({
        target: "form.js-ajax-form"
    });

    initFileUploader();

    $(".js-characteristic-more").on("click", function () {
        $(".row-more").toggleClass("row-more-show");
        $(this).remove();
        return false;
    });

});

var ajaxLoadSpinner = {
    hide: function () {
        return true;
    },
    show: function () {
        return true;
    }
};

// Показ или скрытие нижней панели сайта
var footerbasket = function () {
    var $footerbasket = $(".js-footer-basket"),
            favoriteCount = parseInt($footerbasket.find(".js-favorite_count").text()),
            comparisonCount = parseInt($footerbasket.find(".js-comparison_count").text()),
            viewedCount = parseInt($footerbasket.find(".js-viewed_count").text()),
            basketCount = parseInt($footerbasket.find(".js-shopping-cart__total_count_txt").text()),
            count = favoriteCount + comparisonCount + viewedCount + basketCount;

    if (comparisonCount) {
        $(".comparison_counter_link").show();
    } else {
        $(".comparison_counter_link").hide();
    }

    if (count) {
        $footerbasket.show();
        $("footer").css({'height': '112px'});
        $("main").css({'padding-bottom': '112px'});
    } else {
        $footerbasket.hide();
        $("footer").css({'height': '50px'});
        $("main").css({'padding-bottom': '50px'});
    }
}




