var MetaFormsHandler = {
    bind: function() {
        if (arguments.length === 0) {
            console.log("MetaFormsHandler: укажите jquery селектор формы — target");
            return false;
        }

        var options = arguments[0];
        if (!options.hasOwnProperty("target")) {
            console.log("MetaFormsHandler: укажите jquery селектор формы — target");
            return false;
        }

        if (typeof ajaxLoadSpinner !== 'object') {
            console.log("MetaFormsHandler: для работы необходим ajaxLoadSpinner");
        }


        var SELECTORS = {
            LABELERRORCLASS: "form__error",
            SUCCESSBOXPREFIX: "#js-form__success_box-",
            SUCCESSMSGPREFIX: "#js-form__success_msg-",
            ERRORBOXPREFIX: "#js-form__error_box-",
            ERRORMSGPREFIX: "#js-form__error_msg-",
            RELOADLINKPREFIX: "#js-form__reload-",
            AJAXPRELOADERBOX: "#js-form__preloader-"
        },
        result = {};


        //бинд на форму
        $(options.target).each(function() {
            var $form = $(this),
                formName = $form.attr("name"),
                formActionUri = $form.attr("action") + formName + "/",
                testAction = (options.hasOwnProperty("testAction")) ? options.testAction : false,
                serverWaiting = false;

            var formActions = {
                formFreeze: function($_form) {
                    $form.addClass("js-responce_pending");
                    $form.find("input").attr("disabled", "disabled");
                    $form.find("button").attr("disabled", "disabled");
                    $form.find("textarea").attr("disabled", "disabled");
                    $form.find("select").attr("disabled", "disabled");
                },
                formUnFreeze: function($_form) {
                    $form.removeClass("js-responce_pending");
                    $form.find("input").removeAttr("disabled");
                    $form.find("button").removeAttr("disabled");
                    $form.find("textarea").removeAttr("disabled");
                    $form.find("select").removeAttr("disabled");
                },
                /**
                 *
                 * @param {function} _nextAction функция выполняемая после сброса формы (после отработки анимаций)
                 * @param {bool} _withAnimation использование анимации
                 * @param {bool} removeFieldValues удаления значений в полях
                 */
                formReset: function(_nextAction, _withAnimation, removeFieldValues, $_form) {
                    var nextAction = _nextAction || function() {
                    },
                        withAnimation = _withAnimation || false;
                    if (removeFieldValues) {
                        $form.trigger("reset");
                    }

                    $form.find("label." + SELECTORS.LABELERRORCLASS).removeClass(SELECTORS.LABELERRORCLASS);
                    if (withAnimation) {
                        $form.slideDown();
                        $(SELECTORS.SUCCESSBOXPREFIX + formName).slideUp(300);
                        if ($(SELECTORS.ERRORBOXPREFIX + formName).length) {
                            $(SELECTORS.ERRORBOXPREFIX + formName).slideUp(300, function() {
                                nextAction();
                            });
                        } else {
                            nextAction();
                        }
                    } else {
                        $form.show();
                        $(SELECTORS.SUCCESSBOXPREFIX + formName).hide();
                        $(SELECTORS.ERRORBOXPREFIX + formName).hide();
                        nextAction();
                    }
                },
                beforeFormSend: function($_form, nextAction) {
                    ajaxLoadSpinner.show($(SELECTORS.AJAXPRELOADERBOX + formName));
                    nextAction();
                },
                onSuccessResponse: function($_form, msg) {
                    $(SELECTORS.SUCCESSMSGPREFIX + formName).html(msg);
                    $form.slideUp(300);
                    $(SELECTORS.SUCCESSBOXPREFIX + formName).slideDown(300);
                    $(SELECTORS.RELOADLINKPREFIX + formName).slideDown(300);

                    window.location.hash = "#form-" + formName + "-success";
                },
                onErrorResponse: function($_form, msg, errors) {
                    $.each(errors, function(name, params) {
                        $form.find("label[for=" + params.key + "]").addClass(SELECTORS.LABELERRORCLASS);
                    });

                    if (!msg) {
                        var errRequired = "";

                        for (var i in errors) {
                            if (errors[i].constraint == "required") {
                                errRequired = 'Пожалуйста, корректно заполните отмеченные поля<br>';
                            } else {
                                msg += errors[i].message + "<br>";
                            }
                        }

                        msg = errRequired + msg;
                    }

                    $(SELECTORS.ERRORMSGPREFIX + formName).html(msg);
                    $(SELECTORS.ERRORBOXPREFIX + formName).slideDown(300);
                },
                onServerError: function($_form) {
                    alert("При отправке данных произошла ошибка. Попробуйте обновить страницу.");
                }
            };

            //переопределение страндартных функций
            for (var paramName in options) {
                if (formActions.hasOwnProperty(paramName)) {
                    formActions[paramName] = options[paramName];
                }
            }


            $form.on("submit", function(e) {
                e.preventDefault();

                if (!serverWaiting) {
                    serverWaiting = true;

                    var formData = {};

                    //скрытые (display:none) поля не собираются
                    $form.find("select, input, textarea").each(function() {
                        if (($(this).attr("data-ajax_disabled") === undefined) && $(this).attr("name")) {
                            if ($(this).attr("type") == "checkbox") {
                                if ($(this).is(":checked")) {
                                    formData[$(this).attr("name")] = true;
                                }
                            } else if ($(this).attr("type") == "radio") {
                                if ($(this).is(":checked")) {
                                    formData[$(this).attr("name")] = $(this).val();
                                }
                            } else {
                                formData[$(this).attr("name")] = $(this).val();
                            }
                        }
                    });

                    formActions.formReset(function() {
                        formActions.formFreeze($form);

                        formActions.beforeFormSend($form, function() {
                            switch (testAction) {
                                case "responseError":
                                    //иммитация серверной загруженности
                                    setTimeout(function() {
                                        serverWaiting = false;
                                        ajaxLoadSpinner.hide($(SELECTORS.AJAXPRELOADERBOX + formName));
                                        formActions.formUnFreeze($form);

                                        var testErrorList = {};
                                        for (var item in formData) {
                                            testErrorList[item] = {
                                                key: item
                                            };
                                        }
                                        formActions.onErrorResponse($form, "Тест сообщения об ошибках формы", testErrorList);
                                    }, 1000);
                                    break

                                case "responseSuccess":
                                    //иммитация серверной загруженности
                                    setTimeout(function() {
                                        serverWaiting = false;
                                        ajaxLoadSpinner.hide($(SELECTORS.AJAXPRELOADERBOX + formName));
                                        formActions.formUnFreeze($form);

                                        formActions.onSuccessResponse($form, "<p>Тест успешной отправки сообщения</p>");
                                    }, 1000);
                                    break

                                case "serverError":
                                    //иммитация серверной загруженности
                                    setTimeout(function() {
                                        serverWaiting = false;
                                        ajaxLoadSpinner.hide($(SELECTORS.AJAXPRELOADERBOX + formName));
                                        formActions.formUnFreeze($form);

                                        formActions.onServerError($form);
                                    }, 1000);
                                    break

                                default:
                                    $.ajax({
                                        type: 'POST',
                                        dataType: 'json',
                                        url: formActionUri,
                                        data: formData,
                                        success: function(response) {
                                            var status = response[0],
                                                errors = response[0].errors,
                                                serverMsg = response[1];

                                            serverWaiting = false;
                                            ajaxLoadSpinner.hide($(SELECTORS.AJAXPRELOADERBOX + formName));
                                            formActions.formUnFreeze($form);

                                            if (status.ok) {
                                                formActions.onSuccessResponse($form, serverMsg);
                                            } else {
                                                formActions.onErrorResponse($form, serverMsg, status.errors);
                                            }
                                        },
                                        error: function() {
                                            formActions.onServerError($form);
                                        }
                                    });
                                    break
                            }
                        });
                    }, true, false, $form);
                }
            });

            $(SELECTORS.RELOADLINKPREFIX + formName).on("click", function(e) {
                formActions.formReset(false, true, true, $form);
                e.preventDefault();
            });

            result[formName] = {
                target: $form,
                SELECTORS: SELECTORS,
                formActionUri: formActionUri,
                formActions: formActions
            };
        });


        return result;
    }
}

window.MetaFormsHandler = MetaFormsHandler;